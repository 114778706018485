import {brandConfigObjectMap} from "@/brand/brandConfig";
export const brandConfigMap = new Map();

brandConfigMap.set('http://localhost:8080', getBrandConfig('dls-za-en'));
brandConfigMap.set('https://spiritday2024.stopclock.co.za', getBrandConfig('dls-za-en'));
brandConfigMap.set('https://stopclock.co.za', getBrandConfig('vc-za-en'));
brandConfigMap.set('https://staging.stopclock.co.za', getBrandConfig('vc-za-en'));
brandConfigMap.set('https://www.stopclock.co.za', getBrandConfig('vc-za-en'));
brandConfigMap.set('http://192.168.88.174:8080', getBrandConfig('vc-za-en'));

export function getBrandConfig(brand) {
    const brandConfigObject = brandConfigObjectMap.get(brand);
    return {
        brand,
        productId: brandConfigObject.productId,
        productTitle: brandConfigObject.productTitle,
        categoryId: brandConfigObject.categoryId,
        showTermsConditions: brandConfigObject.showTermsConditions,
        showDataWon: brandConfigObject.showDataWon,
        serviceId: brandConfigObject.serviceId,
        quizIncAllAccessServiceId: brandConfigObject.quizIncAllAccessServiceId,
        newIntegration: brandConfigObject.newIntegration,
        baseRoute: brandConfigObject.baseRoute,
    }
}
