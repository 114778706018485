export const brandConfigObjectMap = new Map();

export const Brands = {
    VodacomSouthAfricaEnglish: 'vc-za-en',
    DlsSouthAfricaEnglish : 'dls-za-en',
};

brandConfigObjectMap.set(Brands.VodacomSouthAfricaEnglish, {
    productId: 3,
    productTitle: 'Stop The Clock',
    categoryId: null,
    showTermsConditions: true,
    showDataWon: true,
    serviceId: 'vc-fanclash-quizinc-03',
    quizIncAllAccessServiceId: 'vc-fanclash-quizinc-02',
    newIntegration: false,
    baseRoute: '/welcome'
});

brandConfigObjectMap.set(Brands.DlsSouthAfricaEnglish, {
    productId: 4,
    productTitle: 'Spirit Day 2024',
    categoryId: 43,
    showTermsConditions: false,
    showDataWon: false,
    baseRoute: '/login',
    serviceId: 'dls-free',
});